import { DateTimeFormatter, ZonedDateTime, nativeJs } from "@js-joda/core";

// prisma automatically stores dates in UTC!
// if displaying prisma dates in the code, prisma automatically converts them back, but attention!
// it converts them back into the timezone of the CURRENT RUNTIME.
// so, when displaying in different user timezones, we need to convert the dates ourselves.
// .toISOString always returns UTC time! Do not use it!

export function getTodaysDate(dayManipulator?: number): Date {
  let currentDateTime = new Date();
  if (dayManipulator) {
    currentDateTime.setDate(currentDateTime.getDate() + dayManipulator);
  }
  currentDateTime.setHours(0, 0, 0, 0);
  return currentDateTime;
}

export function getTomorrowsDate(): Date {
  let tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  tomorrow.setHours(0, 0, 0, 0);
  return tomorrow;
}

export function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function getDateWithoutTime(dateTime: Date): Date {
  if (dateTime) {
    dateTime.setHours(0, 0, 0, 0);
  }
  return dateTime;
}

export function parseDateString(dateString: string): Date | null {
  const [day, month, year] = dateString
    .split(".")
    .map((part) => parseInt(part, 10));
  if (!day || !month || !year) return null;
  // JavaScript Date constructor takes month index from 0 (January is 0, December is 11)
  return new Date(year, month - 1, day);
}

function formatDateForInput(date: Date | null | undefined, isHidden: boolean) {
  if (!date) return "";
  // do NOT do this! toISOString converts into UTC, so we are one hour off for our timezone.
  // return date.toISOString().slice(0, 10);

  // instead, use this functions, as they return the elements of the local timezone (runtime timezone!)
  let year = date.getFullYear(); // Get local year
  let month = String(date.getMonth() + 1).padStart(2, "0"); // Get local month (0-indexed, so add 1)
  let day = String(date.getDate()).padStart(2, "0"); // Get local day

  if (isHidden) {
    return `${year}-${month}-${day}`; // Format in yyyy-MM-dd
  } else {
    return `${day}.${month}.${year}`; // Format in dd.MM.yyyy
  }
}

export function formatDateForInputDisplayedValue(
  date: Date | null | undefined
) {
  return formatDateForInput(date, false);
}

export function formatDateForInputHiddenValue(date: Date | null | undefined) {
  return formatDateForInput(date, true);
}

export function printSafeDateStringRange(dateFrom: Date, dateTo: Date): string {
  let fromString = printSafeDateString(dateFrom);
  let toString = printSafeDateString(dateTo);
  if (fromString === toString) {
    return fromString;
  } else {
    return `${fromString} - ${toString}`;
  }
}

export function printSafeDateString(val: Date | null): string {
  if (val === null) {
    return "";
  }

  /* v8 ignore next 6 */
  if (typeof val === "string") {
    let dateString = ZonedDateTime.parse(val).format(
      DateTimeFormatter.ofPattern("dd.MM.yyyy")
    );
    return dateString;
  }
  try {
    let DateUTCString = nativeJs(val).format(
      DateTimeFormatter.ofPattern("dd.MM.yyyy")
    );
    return DateUTCString;
    /* v8 ignore next 3 */
  } catch (e) {
    throw e;
  }
}
